html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: SignikaNegative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "SignikaNegative";
  src: url("../public/fonts/SignikaNegative-VariableFont_wght.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-bg-image {
  background-image: url("/public/6.jpg");
   background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.theme-text {
  color: #4AA848;
}