.text-fall {
    animation: fall 5s ease;
}

@keyframes fall {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

.origin-left {
    transform-origin: left;
}

.opacity-0 {
    opacity: 0;
}


@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.animated-text {
    animation: slideInFromLeft 1s ease-out;
    animation-fill-mode: both;
}